import React from 'react'

import {
  CountdownWrapper,
  MobileWrapper,
  DesktopWrapper,
} from './Banner.styles'

import Timer from '../Timer'

const Banner = ({ desktopTemplate, text, time, isMobile, hideTimer }) => {
  return (
    // The isMobile prop controls the position: fixed since it has to be handle at the most outer element level
    <CountdownWrapper desktopTemplate={desktopTemplate} isMobile={isMobile}>
      {isMobile ? (
        <MobileWrapper>
          <Timer
            text={text}
            time={time}
            hideTimer={hideTimer}
            isMobile={isMobile}
          />
        </MobileWrapper>
      ) : (
        <DesktopWrapper>
          <Timer text={text} time={time} hideTimer={hideTimer} />
        </DesktopWrapper>
      )}
    </CountdownWrapper>
  )
}

export default Banner
