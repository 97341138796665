import styled from '@emotion/styled'

export const TimerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.isMobile ? '0' : '16px')};
`

export const TimeValues = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'row' : 'column')};
  justify-content: center;
  gap: ${(props) => (props.isMobile ? '4px' : '12px')};

  .colon {
    font-size: ${(props) => (props.isMobile ? '32px' : '52px')};
  }
`

export const Value = styled.div`
  flex-direction: column;
  justify-content: center;
  display: flex;
  align-items: ${(props) => (props.isMobile ? 'center' : '')};
  font-size: ${(props) => (props.isMobile ? '32px' : '52px')};
  line-height: ${(props) => (props.isMobile ? '' : '42px')};
  text-align: center;
  font-weight: bold;

  .identifier {
    font-size: ${(props) => (props.isMobile ? '20px' : '28px')};
    line-height: ${(props) => (props.isMobile ? '' : '28px')};
    font-weight: normal;
  }
`

export const TextStyles = styled.div`
  color: white;
  font-size: 12px;
  line-height: 16px;
  max-width: 170px;
  text-align: center;
`
