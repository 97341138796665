import React from 'react'
import { TimerWrapper, Value, TimeValues, TextStyles } from './Timer.styles'

const Timer = ({ text, time, hideTimer, desktopPosition, isMobile }) => {
  return (
    <TimerWrapper desktopPosition={desktopPosition} isMobile={isMobile}>
      <TextStyles>{text}</TextStyles>
      {!hideTimer && (
        <TimeValues isMobile={isMobile}>
          <Value isMobile={isMobile}>
            {time?.days} <span className="identifier">days</span>
          </Value>
          {isMobile && <span className="colon">:</span>}
          <Value isMobile={isMobile}>
            {time?.hours} <span className="identifier">hours</span>
          </Value>
        </TimeValues>
      )}
    </TimerWrapper>
  )
}

export default Timer
