/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import girlKissingGrandma from '../images/heroes/girl-kissing-grandma.jpg'
import halfMillionInfographic from '../images/illustrations/half-a-mil-infographic.svg'

import { StaticImage } from 'gatsby-plugin-image'
import CountdownTimer from '../components/Countdown'
import DynamicCTACard from '../components/DynamicCTACard'
import SeoCtaBar from '../components/SeoCtaBar'
import footer from '../components/Footer'
import header from '../components/Header'

import {
  Columns,
  Column,
  Hero,
  LinkButton,
  List,
  ListItem,
  SplitContent,
  Stack,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

const Home = ({ data }) => {
  const { rotatedNumber } = useMapi()

  const brandyList = [
    'disclaimer-thirdpartymarketing',
    'disclaimer-plan',
    'disclaimer-evidence',
    'dsiclaimer-changenotice',
    'disclaimer-aetnabrand',
    'disclaimer-clicopyright',
    'disclaimer-incompletedescrip',
    'disclaimer-whyaetnalp',
  ]

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data?.allBrandyDisclaimer?.edges),
        seo: {
          title:
            'Aetna 2023 Medicare Insurance | 855-200-5685 | Plans & Coverage',
          description:
            'Compare Aetna Medicare plans and let us help you enroll in the one that fits your healthcare needs. Call us today at 855-200-569',
          canonical: 'https://www.aetnamedicaredirect.com/',
          robots: 'nofollow,noindex',
        },
        path: '/why-aetna',
        promoCode: '88487',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate:
      'Aetna 2023 Medicare Insurance | 855-200-5685 | Plans & Coverage',
    defaultRobots: 'nofollow,noindex',
    main: (
      <>
        <div className="hero-content">
          <div className="wrapper">
            <Hero
              variant="full"
              className="leshen-hero"
              image={
                <img
                  src={girlKissingGrandma}
                  alt="little girl kisses her grandmother on the cheek"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1">
                    Aetna Medicare—a name you can trust{' '}
                  </Typography>

                  <DynamicCTACard />
                </>
              }
            />
          </div>
        </div>
        <SeoCtaBar />
        <CountdownTimer />
        <SplitContent
          backgroundColor="lightGray"
          alignImageToBottom={false}
          image={
            <img
              src={halfMillionInfographic}
              alt="almost half a million Medicare inquires have been made on aetnamedicaredirect.com in the past 5 years"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">
                Medicare can be confusing—we’re here to make it clear.
              </Typography>
              <Typography variant="body">
                When you turn to Aetna, you turn to a nationally trusted name
                for Medicare information. We’re all about helping people like
                you live healthier lives. Call for:
              </Typography>
              <List>
                <ListItem>Nationwide licensed agents</ListItem>
                <ListItem>
                  Medicare experts with answers to your questions
                </ListItem>
                <ListItem>Medicare options for all walks of life</ListItem>
              </List>
            </>
          }
        />

        <VariableContent
          mainContent={
            <>
              <Typography variant="h2">
                Want to learn more? Choose an option that best fits your
                schedule.
              </Typography>
            </>
          }
          alignMainContent="center"
        >
          <Stack>
            <Columns>
              <Column className="card center-content bg-light-gray">
                <div className="top-content">
                  <Typography variant="h6" color="primary">
                    Talk to an agent today!
                  </Typography>
                  <StaticImage
                    src="../images/icons/purple-phone.svg"
                    alt="phone icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="body">
                    Call {rotatedNumber}
                    <br />
                    TTY: 711, available 24/7
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to={`tel:${rotatedNumber}`}
                  color="primary"
                  className="margin-x-auto"
                >
                  Call {rotatedNumber}
                </LinkButton>
              </Column>
              <Column className="card center-content bg-light-gray">
                <div className="top-content">
                  <Typography variant="h6" color="primary">
                    Submit a request form
                  </Typography>
                  <StaticImage
                    src="../images/icons/purple-checklist.svg"
                    alt="checklist icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="body">
                    Fill out a request form to begin the enrollment process in
                    the plan that's right for you.
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to="/form"
                  color="primary"
                  className="margin-x-auto"
                >
                  Fill out a form
                </LinkButton>
              </Column>
              <Column className="card center-content bg-light-gray">
                <div className="top-content">
                  <Typography variant="h6" color="primary">
                    Schedule a call for later!
                  </Typography>
                  <StaticImage
                    src="../images/icons/purple-calendar.svg"
                    alt="calendar icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="body">
                    Can't talk now? Ask your questions later!
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to="/schedule"
                  color="primary"
                  className="margin-x-auto"
                >
                  Schedule a call today
                </LinkButton>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
