import React, { useEffect, useState } from 'react'

import useWindowSize from '@leshen/ui/src/hooks/useWindowSize'
import Banner from './Banner'

const CountdownTimer = ({ rotatedNumber }) => {
  const [time, setTime] = useState({})
  const [text, setText] = useState('')
  const [showBanner, setShowBanner] = useState(false)
  const [hideTimer, setHideTimer] = useState(false)
  const windowSize = useWindowSize()
  const isMobile = windowSize.width < 1440

  const calculateTimeLeft = (targetDate) => {
    const now = new Date()
    const difference = targetDate - now
    const days = Math.floor(difference / (1000 * 60 * 60 * 24))
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24)
    return { days, hours }
  }

  useEffect(() => {
    const now = new Date()
    const oct1 = new Date(now.getFullYear(), 9, 1)
    const oct15 = new Date(now.getFullYear(), 9, 15)
    const nov16 = new Date(now.getFullYear(), 10, 16)
    const dec8 = new Date(now.getFullYear(), 11, 8)

    if (now < oct1) {
      setTime(null)
      setShowBanner(false)
    } else if (now >= oct1 && now < oct15) {
      setTime(calculateTimeLeft(oct15))
      setText('Annual Enrollment Starts in')
      setHideTimer(false)
      setShowBanner(true)
    } else if (now >= oct15 && now < nov16) {
      setTime(null)
      setText(`It's Annual Enrollment!`)
      setHideTimer(true)
      setShowBanner(true)
    } else if (now >= nov16 && now < dec8) {
      setTime(calculateTimeLeft(dec8))
      setText('Annual Enrollment Ends in')
      setHideTimer(false)
      setShowBanner(true)
    } else {
      setTime(null)
      setShowBanner(false)
    }
  }, [])

  return showBanner ? (
    <Banner
      isMobile={isMobile}
      time={time}
      text={text}
      hideTimer={hideTimer}
      rotatedNumber={rotatedNumber}
    />
  ) : null
}

export default CountdownTimer
