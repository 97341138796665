import styled from '@emotion/styled'

import { colors } from '../../../../theme' // Adjust the import path according to your setup

export const CountdownWrapper = styled.div`
  padding: 12px;
  color: white;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
  background-color: ${colors.primary};
  max-width: ${(props) => (props.isMobile ? '' : '127px')};
  position: ${(props) => (props.isMobile ? 'sticky' : 'fixed')};
  box-shadow: rgb(0, 0, 0, 0.35) -2px 3px 7px;
  z-index: 12;
  right: ${(props) => (props.isMobile ? '' : '0')};
  top: ${(props) => (props.isMobile ? '0' : '80px')};

  border-top-right-radius: ${(props) => (props.isMobile ? '0' : '0')};
  border-top-left-radius: ${(props) => (props.isMobile ? '0' : '10px')};
  border-bottom-right-radius: ${(props) => (props.isMobile ? '10px' : '0')};
  border-bottom-left-radius: ${(props) => (props.isMobile ? '10px' : '10px')};
`

export const MobileWrapper = styled.div``

export const DesktopWrapper = styled.div``
